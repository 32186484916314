/**
 * PrimeNG 4.3.0
 * Customization of PrimeNG File components
 */

.p-fileupload {
  .p-fileupload-files {
    display: block;
  }

  .p-button {
    color: $primary-grey;
    font-weight: 700;
    border: 1px solid $primary-grey;
    background: $secondary-white;
  }

  .p-button-icon-left {
    left: 0.3em;

    &.pi-plus,
    &.pi-upload {
      font-size: 1.5em;
      //background-image: url($browse-file-icon);

      &:before {
        content: "\e964";
      }
    }
    /*
    &.pi-upload {
      background-image: url($upload-icon);
    }*/
  }

  .p-button {
    &:not(.p-disabled):hover,
    &:not(.p-disabled):active,
    &:not(.p-disabled):focus {
      color: $primary-dark-grey;
      border: 1px solid $primary-grey;
      background: $secondary-white;
    }
  }

  .p-button-text {
    padding: 0.4em 1em 0.4em 2.1em;
  }

  .p-fileupload-buttonbar {
    text-align: right;
    border: 0;
    padding: 0;
    margin-bottom: 15px;
    position: relative;
    background: none;

    .p-button {
      &:last-child {
        margin-right: 0;
      }
    }

    .pi-filename {
      text-align: left;
      position: absolute;
      top: 0;
      left: 0;
      right: 140px;
      line-height: 30px;
      height: 32px;
      border: 1px solid $primary-grey;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    .pi-filename-input {
      width: 100%;
      height: 100%;
      border: none;
    }
  }

  .p-fileupload-content {
    text-align: center;
    border: 2px dashed $secondary-grey !important;
    border-radius: 0;
    padding: 50px 30px;
    margin-bottom: 20px;
  }

  .p-fileupload-row {
    margin-bottom: 20px;

    .p-button {
      font-size: 1.3em;
    }

    .p-button-text {
      padding: 0;
    }
  }
}

.p-fileupload-choose.p-disabled {
  opacity: 1;

  &:hover {
    cursor: not-allowed;
  }
}

.p-fileupload-minimal {
  .p-fileupload {
    margin-top: -20px !important;
  }

  .p-button-icon-left.p-clickable.pi.pi-plus {
    display: none !important;
  }

  .p-fileupload-buttonbar {
    display: inline-block;
    margin: 0;
    position: relative;
    top: 3em;
    left: 1.7em;
    z-index: 1;

    .p-button {
      border: none !important;
      padding: 0;

      .p-button-text {
        padding: 0;
        text-decoration: underline;
        font-weight: normal;
      }
    }
  }

  .p-fileupload-content {
    justify-content: center;
    align-items: center;
    padding-left: 1.5em;
    padding-top: 3em;
    padding-bottom: 3em;
    margin-bottom: 0px !important;
    margin-top: 20px;
  }
}