.dialog-custom {

  .p-dialog-content {
    height: 100%;
    padding-top: 0px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  .dialog-message {
    text-align: center;
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
  }

  .dialog-comment {
    padding-top: 12px;
    height: 100%;
  }

  .dialog-text {
    height: 100%;
    padding: 0em 3.5em;
    overflow-y: auto;
  }

  .dialog-footer {
    display: flex;
    justify-content: center;
    &.space-between {
      justify-content: space-between;
      padding: 0 2rem;
    }

    .btn {
      min-width: 120px;
      width: auto;
      font-weight: normal;
    }
  }

  .close-custom {
    position: absolute;
    right: 10px;
    font-size: 24px;
    color: black;
    cursor: pointer;
  }
}
