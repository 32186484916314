$columns: 12;

@mixin col-x($width) {
  // https://social.msdn.microsoft.com/Forums/ie/en-US/8145c1e8-6e51-4213-ace2-2cfa43b1c018/ie-11-flexbox-with-flexwrap-wrap-doesnt-seem-to-calculate-widths-correctly-boxsizing-ignored?forum=iewebdevelopment
  // flex-wrap doesn't respect box-sizing correctly on IE 11, here is a workaround
  width: 100% / $columns * $width;
  flex-basis: auto;
}

@mixin col-x-size($width, $maxWidth) {
  @media screen and (max-width: $maxWidth) {
    width: 100% / $columns * $width;
    flex-basis: auto;
  }
}

@mixin col-x-list() {
  @for $i from 1 through $columns {
    &-#{$i}-12 {
      @include col-x($i)
    }
  }
}

@mixin col-x-size-list($size, $maxWidth: 1366px) {
  @for $i from 1 through $columns {
    &-#{$size}-#{$i}-12 {
      @include col-x-size($i, $maxWidth)
    }
  }
}
