/**
 * PrimeNG 4.3.0
 * Customization of PrimeNG Overlay components
 */

// .ui-tooltip {
//   max-width: 14.5em;

//   &.ui-tooltip-top,
//   &.ui-tooltip-bottom,
//   &.ui-tooltip-left,
//   &.ui-tooltip-right {
//     padding: 0 0.45em;
//   }

//   &.ui-tooltip-top {
//     .ui-tooltip-arrow {
//       bottom: -0.45em;
//       margin-left: -0.45em;
//       border-width: 0.45em 0.45em 0;
//       border-top-color: $primary-dark-grey;
//     }
//   }

//   &.ui-tooltip-left {
//     .ui-tooltip-arrow {
//       margin-top: -0.45em;
//       border-width: 0.45em 0 0.45em 0.5em;
//       border-left-color: $primary-dark-grey;
//     }
//   }

//   &.ui-tooltip-right {
//     .ui-tooltip-arrow {
//       margin-top: -0.45em;
//       border-width: 0.45em 0.45em 0.45em 0;
//       border-right-color: $primary-dark-grey;
//     }
//   }

//   .ui-tooltip-text {
//     font-weight: 500;
//     border-radius: 0;
//     padding: 8px 15px;
//     background-color: $primary-dark-grey;
//   }
// }

// .ui-dialog {
//   .ui-dialog-titlebar {
//     border-bottom: solid 1px $primary-grey;
//     background: $secondary-white;
//     padding: 1em 1.5em;

//     .ui-dialog-title {
//       font-size: 14px;
//       vertical-align: middle;
//       color: $primary-grey;
//     }

//     .ui-dialog-titlebar-close {
//       span {
//         font-size: 1.3em;
//       }
//     }
//   }

//   .ui-dialog-content {
//     padding: 1em 1.5em;
//   }
// }

.cpoint-overlay-panel {
  .p-overlaypanel-content {
    padding: 4px 24px 4px 14px;
    color: unset;
    background-color: #363636;
    border-radius: 0px;
    font-size: 12px;
  }

  .p-overlaypanel-close {
    width: 20px;
    height: 20px;
    line-height: 20px;
    right: 20px;
    border-radius: 0;
    border: none;
    color: #fff;

    &:hover {
      background-color: transparent;
      color: #ddd;
    }

    &:focus {
      -webkit-box-shadow: none;
      box-shadow: none;
    }
  }
}

.cpoint-overlay-panel.specific-value {
  margin-left: 15px;
}

.job-header-billing-info-panel {
  z-index: 2 !important;
  background-color: #fff;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2);
  padding: .5em 1em;

  .p-overlaypanel-content .billing-info-list {
    list-style: none;
    padding: 5px;
    margin-bottom: 0;

    > li {
      line-height: 28px;
    }
  }
}

.well-tooltip {
  max-width: 800px !important;
  width: auto !important;

  .ui-tooltip .ui-tooltip-text {
    width: auto !important;
    white-space: nowrap;
    width: fit-content;
  }
}

.ui-sidebar-mask {
  z-index: 1000;
}

p-overlayPanel {
  font-weight: 700;
}
