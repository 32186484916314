/**
 * PrimeNG 4.3.0
 * Customization of PrimeNG Input components
 */

/**
 * PrimeNG Datetime Picker
 */
// .ui-calendar {
//   display: block;

//   .ui-inputtext {
//     display: inline-block;

//     &:hover {
//       cursor: pointer;
//     }

//     &[readonly] {
//       background-color: $input-background-color;
//     }

//     &:disabled {
//       background-color: #eeeeee;

//       &:hover {
//         cursor: not-allowed !important;
//       }
//     }
//   }

//   .ui-calendar-button {
//     position: absolute;
//     width: 25px;
//     top: 0;
//     right: 0;
//     margin: 0;
//     border-radius: 0;
//     border: 1px solid $primary-grey;
//     border-left: 0 none;
//     background: $primary-grey;
//     color: $secondary-white;

//     &:hover,
//     &:enabled:hover,
//     &:focus,
//     &:active,
//     &:active:focus,
//     &:focus-within {
//       color: $secondary-white;
//       border-color: $primary-dark-grey;
//       background-color: $primary-dark-grey;
//     }

//     &:disabled {
//       color: $secondary-dark-grey;
//       border-color: $secondary-dark-grey;
//       background-color: $secondary-grey;
//     }
//   }
// }

// .ui-calendar {
//   .ui-datepicker {
//     //padding-right: 25px;
//     //width: 100%;
//     min-width: 226px;

//     table td {
//       padding: 0.2em;
//     }

//     &.p-component {
//       .ui-datepicker-calendar {
//         thead {
//           th {
//             padding: 0.7em 0.3em;
//           }
//         }

//         td {
//           a.p-highlight {
//             background-color: $color-5;
//           }

//           a.ui-state-active {
//             color: $secondary-white;
//             background-color: $secondary-red;
//           }
//         }

//         td:not(.p-disabled) {
//           a:hover {
//             color: $secondary-white;
//             background-color: $secondary-red;
//           }
//         }
//       }
//     }
//   }
// }

/**
 * PrimeNG Checkbox
 */
$radio-checkbox-height: 20px;
$radio-checkbox-border-width: 1px;

// .p-chkbox .p-chkbox-box,
// .p-radiobutton-box {
//   width: $radio-checkbox-height;
//   height: $radio-checkbox-height;
//   border-style: solid;

//   &.p-state-default {
//     border-width: $radio-checkbox-border-width;
//     border-color: $secondary-dark-grey;
//     background-color: #fff;
//   }

//   &.p-state-active {
//     color: $secondary-red;
//     border-width: $radio-checkbox-border-width;
//     border-color: $primary-grey;
//     background: $secondary-white;
//   }

//   &:not(.p-disabled):not(.p-state-active):hover {
//     color: $secondary-white;
//     border-color: $primary-grey;
//     background: $secondary-white;
//   }

//   &.p-focus {
//     box-shadow: none;
//     background: $secondary-white;
//   }

//   .fa {
//     margin-top: 0;
//     line-height: $radio-checkbox-height - 2 * $radio-checkbox-border-width;
//   }

//   .pi-check {
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     height: 100%;

//     &:before {
//       font-weight: $bold;
//     }
//   }
// }

// .p-radiobutton-label {
//   margin-bottom: 0;
// }

// .p-chkbox .p-chkbox-box {
//   border-radius: 0px;
// }

// .p-chkbox-label {
//   margin-bottom: 0;
// }

// .p-radiobutton-box {
//   position: relative;

//   .fa-circle:before {
//     content: "";
//   }

//   .p-radiobutton-icon {
//     font-family: $primary-font;
//     width: 8px;
//     height: 8px;
//     border-radius: 8px;
//     position: absolute;
//     top: 50%;
//     left: 50%;
//     margin-left: -4px;
//     margin-top: -4px;

//     &:before {
//       display: none;
//     }
//   }

//   &.p-state-active .p-radiobutton-icon {
//     background-color: #f0544f;
//   }
// }

// .p-inputtext {
//   color: #333333;
//   background: #ffffff;
//   padding: 0.429em;
//   -moz-transition: border-color 0.2s;
//   -o-transition: border-color 0.2s;
//   -webkit-transition: border-color 0.2s;
//   transition: border-color 0.2s;
//   -webkit-appearance: none;
//   -moz-appearance: none;
//   appearance: none;

//   :enabled:focus:not(.p-state-error) {
//     border-color: #007ad9;
//     outline: 0 none;
//     -webkit-box-shadow: none;
//     -moz-box-shadow: none;
//     box-shadow: none;
//   }
// }

// .p-highlight {
//   border-color: #156090;
//   background-color: #186ba0;
//   color: #fff;
// }

textarea.p-inputtext {
  border: 1px solid $secondary-dark-grey;
}

.p-calendar .p-datepicker {
  min-width: 226px !important;
}

.p-tooltip .p-tooltip-text {
  padding: 0.5rem;
}