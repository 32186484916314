.p-accordion {
  .p-accordion-header {
    border: 0;
    border-radius: 0px !important;
    //box-shadow: 0px 0px 4px rgba(1, 1, 1, 0.5);

    > a {
      font-weight: $bold;
      color: $secondary-white !important;
      text-decoration: none;
      padding: 1em 0.8em;
      background-color: $primary-red !important;
      border: 0 !important;
      outline: none;
    }

    p-header {
      width: 100%;
    }
  }

  .p-accordion-content {
    //box-shadow: 0px 0px 5px rgba(1, 1, 1, 0.3);
    border: 1px solid #cccccc;
    border-top: 0;
  }

  .p-accordion-toggle-icon {
    font-size: 1.7em;
    color: #fff !important;
  }
}

.p-accordion-wrapper {
  .tab-header {
    display: inline-block;

    span {
      vertical-align: middle;
      font-size: 16px;
    }

    .tab-header-title {
      margin-right: 5px;
    }
  }

  .more-info-icon {
    display: none;

    .more-info-icon-target {
      position: relative;
      top: -30px;
      left: 0px;

      .fa-info-circle {
        font-size: 16px;
      }
    }
  }

  .tab-body {
    min-height: 100px;
  }

  .tab-section-required {
    .tab-header-title {
      &:after {
        content: "*";
      }
    }

    .more-info-icon {
      display: inline-block;
    }
  }

  .tab-section-not-required {
    .more-info-icon {
      display: inline-block;
    }
  }
}

//.p-accordion .p-accordion-content {border: 0; padding: 2.5em 1em}
