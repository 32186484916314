.section {
  padding: 10px 0;
  border-bottom: 2px solid #d7d7d7;
  margin-bottom: 5px;
  position: relative;

  .header {
    color: #333;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    margin: 0;
  }

  .heading-elements {

    line-height: 24px;

    .list-inline {
      margin-bottom: 0;
    }

    .list-inline-item {
      padding: 0;

      & + .list-inline-item {
        margin-left: 20px;
      }

      > a {
        color: #1e1e1e;

        &:hover {
          text-decoration: none;
          color: #5a5a5a;
        }

        i {
          font-size: 20px;
          position: relative;
          bottom: -3px;
          left: -3px;
        }
      }
    }
  }
}
