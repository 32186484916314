.p-dropdown {
  width: 100%
}

.p-multiselect {
  width: 100%
}

/*.ui-dropdown {
  min-width: auto;
  background: #fff;

  .ui-dropdown-label {
    color: $primary-dark-grey;
  }

  .ui-placeholder {
    color: #999;
  }

  .ui-dropdown-trigger {
    color: $primary-dark-grey;
  }

  &.p-disabled {
    background: #eee;

    opacity: 1;
    cursor: not-allowed !important;

    .ui-dropdown-label {
      background-color: #eeeeee;
      box-shadow: 0 3px 6px rgba(168, 168, 168, 0.2);
      cursor: not-allowed !important;
    }

    .ui-dropdown-trigger {
      background-color: #eeeeee;
      cursor: not-allowed !important;
    }
  }
}
*/

.p-dropdown {
  border-radius: 0;
  border: 1px solid $secondary-grey;
  width: 100% !important;

  label.p-dropdown-label {
    white-space: pre;
    border-radius: 0;
    padding: 5px 40px 5px 10px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .p-dropdown-panel {
    border-radius: 0;
    border: 1px solid $secondary-grey;
    border-top: 0;
    left: -1px !important; // Need -1px to keep dropdown list be aligned with label
    right: -1px; // Need -1px to keep dropdown list be aligned with label
    margin-top: -1px; // Need -1px to keep dropdown list be aligned with label

    .p-dropdown-list {
      padding: 0;
    }

    .p-dropdown-item {
      border-radius: 0;
      padding: 8px 10px;
      margin: 0;
      word-break: break-word;

      > span {
        white-space: pre-wrap;
      }

      &.p-highlight,
      &:active,
      &:focus,
      &:hover {
        color: $secondary-white !important;
        background-color: $secondary-red !important;
      }
    }
  }
}

.p-dropdown-panel {
  // Z-index of dropdown panel should be bigger than bootstrap modal
  z-index: $zindex-modal + 1;

  .p-dropdown-filter-container {
    padding: 0.429em 0.857em 0.429em 0.857em;
    border-bottom: 1px solid #eaeaea;
    color: #333333;
    background-color: #ffffff;
    margin: 0;

    .p-dropdown-filter {
      width: 100%;
      padding-right: 2em;
      border: 1px solid #ccc;
      color: inherit;
    }
    .p-dropdown-filter-icon {
      top: 50%;
      margin-top: -0.5em;
      right: 1.357em;
      color: #007ad9;
    }
  }
}

/**
 * PrimeNG Dropdown
 */
 /*
.ui-shadow {
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2);
}

.pi-chevron-down {
  width: 15px;
  height: 15px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url($chevron-down-black);
  display: block;
  transition: transform 0.2s ease;
  &.expanded {
    transform: rotateX(180deg);
  }
}

.ui-dropdown-open {
  .pi-chevron-down {
    transform: rotateX(180deg);
  }
}

.ui-multiselect {
  border: 1px solid #d6d6d6;
  box-shadow: 0 3px 6px rgba(168, 168, 168, 0.2);

  .ui-multiselect-label {
    min-height: 28px;
    line-height: 20px;
    padding-left: 5px;
  }

  .ui-multiselect-trigger {
    width: 2em;
  }
}

.ui-multiselect-panel {
  padding: 0;
  border: 1px solid #c8c8c8;
  background-color: #ffffff;
  -webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);

  .ui-multiselect-header {
    padding: 0.429em 0.857em 0.429em 0.857em;
    border-bottom: 1px solid #eaeaea;
    color: #333333;
    background-color: #ffffff;
    margin: 0;

    .ui-multiselect-filter-container {
      float: none;
      //width: 70%;
      display: inline-block;
      vertical-align: middle;
      margin-left: 0;
    }
    .ui-multiselect-filter-container .ui-inputtext {
      padding-right: 2em;
      padding-left: 5px;
      border: 1px solid $secondary-grey;
    }
    .ui-multiselect-filter-container .ui-multiselect-filter-icon {
      color: #007ad9;
      top: 50%;
      margin-top: -0.5em;
      right: 0.5em;
      left: auto;
    }
    .ui-chkbox {
      margin-right: 0.5em;
      float: none;
      vertical-align: middle;
    }
    .ui-multiselect-close {
      color: #848484;
      top: 50%;
      font-size: 16px;
      margin-top: -0.5em;
      -moz-transition: color 0.2s;
      -o-transition: color 0.2s;
      -webkit-transition: color 0.2s;
      transition: color 0.2s;
    }
    .ui-multiselect-close:hover {
      color: #333333;
    }
  }
  .ui-multiselect-items {
    padding: 0;
  }
  .ui-multiselect-items .ui-multiselect-item {
    margin: 0;
    padding: 0.429em 0.857em;
    border: 0 none;
    color: #333333;
    background-color: transparent;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
  }
  .ui-multiselect-items .ui-multiselect-item.p-highlight {
    color: #ffffff;
    background-color: #007ad9;
  }
  .ui-multiselect-items .ui-multiselect-item:not(.p-highlight):not(.p-disabled):hover {
    color: #333333;
    background-color: #eaeaea;
  }
  .ui-multiselect-items .ui-multiselect-item.p-disabled .ui-chkbox-box {
    cursor: auto;
  }
  .ui-multiselect-items .ui-multiselect-item.p-disabled .ui-chkbox-box:hover {
    border: 1px solid #a6a6a6;
  }
  .ui-multiselect-items .ui-multiselect-item.p-disabled .ui-chkbox-box.ui-state-active:hover {
    border-color: #007ad9;
  }
  .ui-multiselect-items .ui-multiselect-item .ui-chkbox {
    position: static;
    display: inline-block;
    vertical-align: middle;
    margin: 0 0.5em 0 0;
  }
  .ui-multiselect-items .ui-multiselect-item label {
    display: inline-block;
    vertical-align: middle;
  }
}
*/
// Typeahead dropdown styles

body > .p-overlaypanel.p-component {
  z-index: 2051 !important;

  .p-datatable {
    table {
      border-spacing: 1px;
      border-collapse: separate;
      background: #d6d6d6;
    }

    tr {
      background: #fff;
    }

    thead th {
      background: $primary-dark-grey;
      color: #fff;
      text-align: center;
      font-weight: normal;
      padding: 5px;
    }

    tbody {
      > tr {
        &.p-highlight,
        &:active,
        &:focus,
        &:hover {
          color: $secondary-white;
          background-color: $secondary-red;
        }
      }
    }
  }

  .p-datatable.p-component.p-datatable-scrollable {
    min-width: 500px;
  }
}

.typeahead-datatable-container, .typeahead-datatable {
  width: 100%;
  height: 100%;
  position: relative;

  // &.dropdown-show {
  //   .pi-chevron-down {
  //     transform: rotateX(180deg);
  //   }
  // }

  // &:not(.dropdown-show){
  //   .pi-chevron-down {
  //     transform: rotateX(0deg);
  //   }
  // }

  &:not(.overpanelTarget) {
    .p-overlaypanel {
      top: 100% !important;
      left: 0 !important;
      right: 0 !important;
    }
  }

  .p-datatable-thead {
    position: sticky;
    top: -1px;
  }
  .p-overlaypanel {
    min-width: 450px;
    top: 100% !important;
    left: 0px !important;

    .p-overlaypanel-content {
      padding: 0px !important;
    }
  }

  .p-datatable > .p-datatable-wrapper {
    table {
      border-spacing: 0px !important;
      border-collapse: collapse !important;
      background: #d6d6d6;
    }

    tr {
      background: #fff;
    }

    table, tr, td {
      border: 1px solid #d6d6d6 !important;
    }

    thead th {
      background: $primary-dark-grey !important;
      color: #fff;
      text-align: center;
      font-weight: normal;
      padding: 5px;
      border: none;
    }

    th:last-child, td:last-child {
      padding-right: 15px !important;
    }

    tbody {
      > tr {
        &.p-highlight,
        &:active,
        &:focus,
        &:hover {
          color: $secondary-white;
          background-color: $secondary-red;
        }
      }
    }

  }
}

.typeahead-dropdown-panel {
  font-size: 1em !important;
  line-height: 1.8;
  display: flex;

  .typeahead-placeholder {
    margin-right: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .typeahead-dropdown {
    margin-left: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ui-dropdown-clear-icon {
    position: absolute;
    right: 2em;
    top: 50%;
    font-size: 1em;
    height: 1em;
    margin-top: -0.5em;
  }
}

.typeahead-datatable-loader {
  position: absolute;
  right: 0.85em;
  top: 15px;
  margin-top: -0.5em;
}
