.p-slider {
    background-image: linear-gradient(#9e9e9e, #c4c4c4);
    border: 1px solid #868686;
}
.p-slider.p-slider-horizontal {
    height: 0.8em;
}
.p-slider.p-slider-horizontal .p-slider-handle {
    top: 50%;
    margin-top: -1em;
}
.p-slider .p-slider-handle {
    height: 2em;
    width: 2em;
    background-color: #ffffff;
    border: 1px solid #868686;
    -moz-border-radius: 100%;
    -webkit-border-radius: 100%;
    border-radius: 100%;
    -moz-transition: border-color 0.1s;
    -o-transition: border-color 0.1s;
    -webkit-transition: border-color 0.1s;
    transition: border-color 0.1s;
}
.p-slider .p-slider-range {
    background-image: linear-gradient(#2d799c, #3794bf);
}
.p-slider-handle:focus {
    outline: 0;
    outline-style: none;
    -moz-outline-style: none;
}
