/**
 *  BUTTONS
 */

.primary-btn {
  font-weight: $bold;
  font-size: 1em;
  font-family: $primary-font;
  color: $secondary-white;
  text-transform: capitalize;
  border: 2px solid $color-1;
  border-radius: 4px;
  padding: 8px 30px;
  background-color: $color-1;
  transition: all 0.2s ease;

  &:hover {
    color: $secondary-white;
    border-color: $primary-dark-grey;
    background-color: $color-1;
  }

  &:focus,
  &:active,
  &:active:focus,
  &:active:hover,
  &:focus-within {
    color: $secondary-dark-grey;
    border-color: $primary-dark-grey;
    background-color: $secondary-white;
    box-shadow: none;
    outline: none;
  }

  &:disabled {
    opacity: 1;
    color: $secondary-dark-grey;
    border-color: $secondary-dark-grey;
    background-color: $secondary-grey;
  }
}

a.primary-btn,
button.primary-btn {
  @extend .primary-btn;
}

.full-width-btn {
  width: 100%;
}

.button-bar {
  display: flex;
  
  button {
    color: $primary-grey;
    font-weight: $bold;
    border: none;
    padding: 8px 10px;
    border-top: 2px solid $primary-grey;
    border-bottom: 2px solid $primary-grey;
    background: $secondary-white;
    flex: 1;
    white-space: nowrap;

    &:hover {
      color: $primary-dark-grey;
    }

    &:focus,
    &:active,
    &:active:focus,
    &:active:hover,
    &:focus-within {
      color: $secondary-dark-grey;
      border-color: $primary-grey;
      border-radius: 4px;
      background-color: $primary-grey;
      box-shadow: none;
      outline: none;
    }
  }
}

.button-icon {
  img {
    margin-right: 5px;
  }

  .pi {
    font-size: 1.8em;
    vertical-align: middle;
    margin-right: 5px;
  }

  .text {
    text-decoration: underline;
    vertical-align: middle;

    &:hover {
      cursor: pointer;
    }
  }
}

// .cancel-button {
//   text-decoration: underline;
//   margin-left: 65px;
//   margin-top: 15px;
//   cursor: pointer;
//   margin-right: 2em;
// }