.adaptive-modal {
  display: flex !important;
  flex-direction: column;

  &.modal-dialog {
    margin: 0;
    z-index: 1008;
    overflow-y: hidden;
    width: 80vw;
    height: 90vh;

    .modal-content {
      height: 100%;
    }

    .modal-header {
      height: 55px;
    }

    .modal-body {
      padding: 1em 1.5em 2.5em 30px;
      flex: 1 1 0px; // Use flex here to apply flexible size in height
      overflow-y: hidden;
    }
  }
}

.m-dialog-admin {
  width: 100%;
  height: auto;
  min-height: 100%;
  margin: 0;
  padding: 0;

  .modal-content {
    position: absolute;
    width: 100%;
    min-height: 100%;
    height: auto;
  }
}

.ui-sidebar {
  .ui-sidebar-close {
    font-size: 3em;
  }
}

.modal-header {
  .icon-help {
    margin-right: 10px;
    font-size: 2em;
    color: #1b1d1f;
  }
}

// .vida-modal {
//   padding: 0px;
//   -ms-overflow-style: -ms-autohiding-scrollbar; // To fix issue of overlapping scroll bar on IE 10+

//   .modal-header {
//     border-bottom: 1px solid #797979;
//     padding: 13px 45px 14px;

//     h1 {
//       color: $color-1;
//       font-size: 32px;
//       line-height: 36px;
//       margin: 0;
//     }
//   }

//   .modal-content {
//     box-shadow: none;
//     border: none;
//     position: absolute;
//     top: 66px;
//     bottom: 65px;
//     left: 0;
//     right: 0;
//     padding: 20px 20px 0;
//     overflow-y: auto;
//   }

//   .modal-footer {
//     position: absolute;
//     bottom: 0;
//     left: 0;
//     right: 0;
//     padding: 25px 25px 12px 25px;
//     border: none;

//     .btn {
//       min-width: 150px;

//       & + .btn {
//         margin-right: 20px;
//       }
//     }
//   }
// }

// .vida-modal.ifact-search-sidebar {
//   width: 100%;
//   height: 100%;
// }

.badge-number {
  background-color: gray;
  padding: 4px 8px;
  border-radius: 50%;
  color: #fff;
  font-family: sans-serif;
  margin-right: 10px;
}

.modal {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.modal-dialog {
  // margin: auto;
  max-width: 100%;
  width: auto;
  display: inline-block;

  .modal-content {
    display: flex;

    > * {
      max-height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
    }

    .modal-header {
      flex: 0 1 auto;

      h1 {
        font-size: 26px;
        line-height: 26px;
        margin: 0;
      }
    }

    .modal-body {
      flex: 1 1 auto;
    }

    .modal-footer {
      flex: 0 1 auto;
    }
  }

  &.full-height,
  &.full-screen-modal {
    margin: 0 auto;
    padding: 0;
    height: 100vh;
    max-height: 100vh;
    width: 100%;
    overflow: hidden;

    .modal-content {
      height: auto;
      margin: 0 auto;
      max-height: 100vh;
      min-height: 100%;
      .modal-header {
        // border-bottom: 0;
      }
    }
  }
  &.full-screen-modal.ifact-full-screen-modal {
    .modal-content {
      overflow: scroll;
    }
  }

  &.custom-model-screen {
    margin: 0 auto;
    padding: 0;
    overflow: hidden;
    width: 35%;
    .modal-content {
      height: auto;
      margin: 0 auto;
    }
  }

  &.full-height {
    max-width: 90%;
    width: 90%;
  }

  &.full-screen-modal {
    .modal-content {
      border-radius: 0;
    }
  }

  // &.full-screen-modal {
  //   width: 100%;
  //   height: 100vh;
  //   margin: 0;
  //   padding: 0;

  //   .modal-content {
  //     height: auto;
  //     min-height: 100%;
  //     border-radius: 0;
  //   }
  // }

  .modal-body {
    overflow: auto;
  }
}

.b-modal-close {
  float: right;
  .pi-times {
    font-size: 1.1em;
  }
}

.full-screen-modal {
  width: 100% !important;
}

.part-screen-modal {
  height: 100vh;
  width: 90% !important;
  padding: 0px !important;

  .p-sidebar-close {
    width: 30px;
    height: 30px;
    top: 12px;

    .pi {
      font-size: 2em;
      color: #000;
    }
  }
}

@media (min-width: 768px) {
  .modal-dialog {
    &.custom-model-screen {
      margin: 0 auto;
      padding: 0;
      overflow: hidden;
      width: 41%;
      .modal-content {
        height: auto;
        margin: 0 auto;
      }
    }
  }
}
