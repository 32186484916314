// @mixin make-col($size) {
//     -ms-flex: 0 0 percentage($size / 12);
//     flex: 0 0 percentage($size / 12);
//     max-width: percentage($size / 12);
// }

// @mixin make-col-group($infix) {
//     @for $i from 1 through 12 {
//         .flx-col#{$infix}-#{$i} {
//             @include make-col($i);
//         }
//     }
// }

// @mixin  make-col-infix($infix) {
//     // Provide basic `.col-{bp}` classes for equal-width flexbox columns
//     .flx-col#{$infix} {
//         flex-basis: 0;
//         flex-grow: 1;
//         max-width: 100%;
//     }
// }

// // new simple flex grid (based on bootstrap 4)
// .flx-row {
//     display: -ms-flexbox;
//     display: flex;
//     -ms-flex-wrap: wrap;
//     flex-wrap: wrap;
//     margin-right: -15px;
//     margin-left: -15px;

    
//     // .flx-col {
//     //     -ms-flex-preferred-size: 0;
//     //     flex-basis: 0;
//     //     -ms-flex-positive: 1;
//     //     flex-grow: 1;
//     //     max-width: 100%;
//     // }

//     > .flx-col, > [class^="flx-col-"] {
//         position: relative;
//         width: 100%;
//         padding-right: 15px;
//         padding-left: 15px;
//     }

//     @include make-col-infix('');
//     @include make-col-group('');

//     @media (min-width: 576px) {
//         @include make-col-infix('-sm');
//         @include make-col-group('-sm');
//     }

//     @media (min-width: 768px) {
//         @include make-col-infix('-md');
//         @include make-col-group('-md');
//     }

//     @media (min-width: 992px) {
//         @include make-col-infix('-lg');
//         @include make-col-group('-lg');
//     }
// }

// .no-gutters {
//     margin-right: 0;
//     margin-left: 0;

//     > .flx-col,
//     > [class*="flx-col-"] {
//       padding-right: 0;
//       padding-left: 0;
//     }
// }
.align-items-center {
    -webkit-box-align: center!important;
    -ms-flex-align: center!important;
    align-items: center!important;
}