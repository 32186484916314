/**
 * PrimeNG Input Switch
 */

.p-inputswitch {
    width: 5em;
    .p-inputswitch-slider {
        width: 5em;
        background: #ccc;
    }

    // .p-inputswitch .p-inputswitch-slider:before {
    //     left: 3rem;
    // }



    &.p-inputswitch-checked .p-inputswitch-slider,
    &.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
        background-color: #333333;
    }

    .p-inputswitch-slider::after {
        content: "Off";
        color: white;
        position: absolute;
        left: 2.25em;
        bottom: 0.1em;
    }

    &.p-inputswitch-checked {
        .p-inputswitch-slider::after {
            content: "On";
            position: absolute;
            left: 1.25em;
            bottom: 0.1em;
            color: white;
        }
        .p-inputswitch-slider:before {
            left: 0.5rem;
            transform: translateX(3.25em);
        }
    }
}
// .p-inputswitch-slider::before {
//     background: #fff;
// }
