.p-autocomplete {
  width: 100%;

  .p-autocomplete-input-token input {
    line-height: 22px;
  }

  .p-autocomplete-items {
      padding: .4em;
  }

  .p-autocomplete-item {
    padding: 8px 10px !important;
  }

  .p-autocomplete-item:hover {
    background: $secondary-red !important;
    color: #fff !important;
    border-radius: 0 !important;
  }

  .p-autocomplete-panel {
    border-radius: 0;
    width: 100%;
  }
}

.p-autocomplete.form-control {
  padding: 0px;
  height: auto;
  min-height: 30px;

  .p-inputtext {
    border-radius: 0px;
    border: 0px;
    width: 100%;
  }
}

.p-autocomplete > .p-autocomplete-input {
  border-radius: unset;
  text-overflow: ellipsis;
  width: 100%;
  height: 32px;
}

.p-autocomplete-multiple-container,
.p-autocomplete-multiple-container input,
.p-autocomplete > input {
  width: 100%;
  border-radius: 0;
}

.p-autocomplete-multiple-container,
.p-autocomplete > input {
  border: 1px solid $secondary-grey;
  box-shadow: 0 3px 6px rgba(168, 168, 168, 0.2);
}