/**
 * PrimeNG 4.3.0
 * Customization of PrimeNG Data components
 */

.ui-table-minimal,
.p-datatable {
  th {
    &.p-state-default {
      color: $secondary-white;
      text-transform: capitalize;
      border: 0;
      background: $primary-dark-grey;
    }

    &.no-text-transform {
      text-transform: none;
    }

    &.p-state-active {
      color: $secondary-white;
      background: $primary-light-grey;
    }

    &.p-sortable-column:not(.p-state-active):hover {
      color: $secondary-white;
      border-color: $primary-light-grey;
      background: $primary-light-grey;
    }
  }

  .action-span {
    .delete-action-button {
      // obsolete. Use .delete-row-btn
      display: none;
      position: absolute;
      top: -7px;
      right: -46px;
      height: calc(100% + 14px);
      color: $secondary-white;
      border-top-right-radius: 35px;
      border-bottom-right-radius: 35px;
      background: white;
      border: 1px solid #ccc;
      border-left: 0px solid white;
      width: 40px;
      outline: none;
      i.fa-times {
        color: $secondary-grey-blue;
        font-size: 2rem;
      }
    }
  }

  .delete-row-btn {
    display: none;
    position: absolute;
    top: 0px;
    right: -38px;
    height: 100%;
    color: #ffffff;
    border-top-right-radius: 35px;
    border-bottom-right-radius: 35px;
    background: white;
    border: 1px solid #ccc;
    border-left: 0px solid white;
    width: 40px;
    outline: none;

    i {
      color: $secondary-grey-blue;
      font-size: 2rem;
    }
  }

  tbody > {
    tr:first-child .delete-action-button {
      background-color: #d4dadd;
    }
    tr:nth-child(even) .delete-action-button {
      background-color: #ffffff;
    }
    tr:nth-child(odd):not(:first-child) .delete-action-button,
    tr:nth-child(odd) .delete-row-btn {
      background-color: #d4dadd;
    }
  }

  .action-col {
    position: relative;
  }

  tr:hover {
    .delete-action-button,
    .delete-row-btn {
      display: block;
    }
  }
}

.ui-table,
.p-datatable {

.p-datatable-wrapper {
  overflow: visible !important;
}

  &.scrollable-table {
    .p-datatable-wrapper {
      height: 100%;
      overflow-x: hidden;
      -ms-overflow-style: scrollbar;
    }
  }

  th.p-sortable-column:not(.p-state-active):hover {
    background-color: $primary-light-grey;
    border-color: $primary-light-grey;
  }

  // .p-sortable-column-icon {
  //   &.pi-sort:before {
  //     content: "\e90f";
  //   }

  //   &.pi-sort-up:before {
  //     content: "\e903";
  //   }

  //   &.pi-sort-down:before {
  //     content: "\e902";
  //   }
  // }

  table {
    width: 100%;
    table-layout: fixed;
  }

  th {
    &.p-state-default {
      color: $secondary-white;
      text-transform: capitalize;
      border: 0;
      background: $primary-dark-grey;
    }

    &.no-text-transform {
      text-transform: none;
    }

    &.p-state-active {
      color: $secondary-white;
      background: $primary-light-grey;
    }

    &.p-sortable-column:not(.p-state-active):hover {
      color: $secondary-white;
      border-color: $primary-light-grey;
      background: $primary-light-grey;
    }
  }

  .action-span {
    .delete-action-button {
      // obsolete. Use .delete-row-btn
      display: none;
      position: absolute;
      top: -7px;
      right: -46px;
      height: calc(100% + 14px);
      color: $secondary-white;
      border-top-right-radius: 35px;
      border-bottom-right-radius: 35px;
      background: white;
      border: 1px solid #ccc;
      border-left: 0px solid white;
      width: 40px;
      outline: none;
      i.fa-times {
        color: $secondary-grey-blue;
        font-size: 2rem;
      }
    }
  }

  .delete-row-btn {
    display: none;
    position: absolute;
    top: 0px;
    right: -38px;
    height: 100%;
    color: #ffffff;
    border-top-right-radius: 35px;
    border-bottom-right-radius: 35px;
    background: white;
    border: 1px solid #ccc;
    border-left: 0px solid white;
    width: 40px;
    outline: none;

    i {
      color: $secondary-grey-blue;
      font-size: 2rem;
    }
  }

  tbody > {
    tr:first-child .delete-action-button {
      background-color: #d4dadd;
    }
    tr:nth-child(even) .delete-action-button {
      background-color: #ffffff;
    }
    tr:nth-child(odd):not(:first-child) .delete-action-button,
    tr:nth-child(odd) .delete-row-btn {
      background-color: #d4dadd;
    }
  }

  .action-col {
    position: relative;
  }

  tr:hover {
    .delete-action-button,
    .delete-row-btn {
      display: block;
    }
  }
}

.no-shadow {
  box-shadow: none !important;
  padding: none !important;

  > div,
  > div > div {
    box-shadow: none !important;
    padding: 0 !important;
  }
}

.no-border-special {
  > div,
  > div > div {
    border: none !important;
  }

  td {
    border: none !important;
  }
}

.ui-table, .ui-table-minimal, 
.p-datatable-wrapper {
  > table {
    border-collapse: separate;

    border: 1px solid #ccc;
    border-top: 0;

    .ui-dropdown {
      .ui-dropdown-label {
        min-height: 28px;
        padding-top: 8px;
      }
    }

    > thead,
    > .p-datatable-thead,
    table > thead {
      > tr > th {
        padding: 10px 5px;
        line-height: 14px;
        background: table-color("header-primary");
        color: #fff;
        border: 0px solid table-color("header-primary");
        border-bottom-color: table-color("border-header");
        border-bottom-width: 3px;
        text-align: left;
      }

      .bg-light th {
        background-color: #ccc;
        color: #444;
      }

      .bg-dark th {
        background-color: table-color("border-header");
        color: #fff;
      }

      &.thead-sm th {
        padding: 6px 5px;
      }

      &.nowrapped > tr {
        white-space: nowrap;
      }
    }

    tbody {
      > tr {
        > td {
          padding: 6px;
          border-right: 1px solid table-color("border-primary");
          border-left: 1px solid table-color("border-primary");
          vertical-align: top;
          word-break: break-word;

          &.vertical-align-middle {
            vertical-align: middle;
          }
        }

        &:nth-child(even) {
          background-color: table-color("row-even");
        }

        &:nth-child(odd) {
          background-color: table-color("row-odd");
        }

        &:first-child > td {
          border-top: 2px solid table-color("border-primary");
        }

        &:last-child > td {
          border-bottom: 2px solid table-color("border-primary");
        }
      }

      &.monochrome {
        > tr:not(:first-child) > td {
          border-top: 1px solid table-color("border-primary");
        }

        > tr:not(:last-child) > td {
          border-bottom: 1px solid table-color("border-primary");
        }

        > tr:nth-child(even) {
          background-color: table-color("row-odd");
        }
      }
    }

    .th-vertical {
      color: #fff;
      font-weight: 700;
      text-align: right;
      border-bottom: 2px solid #fff;
      background: table-color("header-primary");
    }
  }

  .ui-paginator {
    padding: 0;

    .pi {
      font-size: 1.25em;
    }
  }

  .no-fixed-table {
    table-layout: auto;
  }
}

.p-datatable table {
  border-collapse: inherit !important;
}

.p-datatable .p-sortable-column .p-sortable-column-icon,
.p-datatable .p-sortable-column:not(.p-highlight):hover .p-sortable-column-icon {
  color: #fff;
}

.box-shadow {
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
}

.no-fixed-table {
  > .ui-table-wrapper {
    > table {
      table-layout: auto;
    }
  }
}

table.no-fixed-table {
  table-layout: auto;
}

.input-cust-objective {
  width: 40%;
  display: inline-block;
}

.cust-10pt-objective {
  width: 15%;
}

.cust-20pt-objective {
  width: 29%;
}

.cust-7-objective {
  width: 13%;
}

.cust-13-objective {
  width: 16%;
}

.cust-margin-bottom-10 {
  margin-bottom: 10px;
}

.delimiter-objective {
  text-align: center;
  display: inline-block;
  width: 6px;
  margin-right: 5px;

  &:before {
    content: "";
    display: inline-block;
    max-width: 100%;
    overflow: hidden;
    width: 20px;
    height: 3px;
    margin-top: 14px;
    background-color: $primary-dark-grey;
  }
}

.unit-objective {
  height: 30px;
  display: inline-block;
  margin-top: 10px;
}

.inline {
  width: 100%;
  font-size: 12px;

  .inline-left,
  .inline-left input {
    width: 45%;
    display: inline-block;
    text-align: left;
    vertical-align: top;

    .form-control {
      width: 75% !important;
      display: inline-block;
    }

    th > span {
      display: inline-block;
      margin-top: 0px;
      margin-left: 3px;
      @media (min-width: 1900px) {
        margin-top: 1px;
      }
    }
    td > span {
      display: inline-block;
      margin-top: 7px;
      margin-left: 3px;
    }
    span {
      display: inline-block;
      margin-top: 7px;
      margin-left: 3px;
    }
  }

  .inline-right,
  .inline-right input {
    width: 45%;
    display: inline-block;
    text-align: right;
    vertical-align: top;
    margin-left: -5px;

    .form-control {
      width: 75% !important;
      display: inline-block;
      text-align: left;
    }

    th > span {
      display: inline-block;
      margin-top: 0px;
      margin-left: 3px;
      @media (min-width: 1900px) {
        margin-top: 1px;
      }
    }
    td > span {
      display: inline-block;
      margin-top: 7px;
      margin-left: 3px;
    }
    span {
      display: inline-block;
      margin-top: 7px;
      margin-left: 3px;
    }
  }

  .inline-right ::deep p-inputmask {
    .form-control {
      width: 75% !important;
      display: inline-block;
    }

    span {
      display: inline-block;
      margin-top: 7px;
      margin-left: 3px;
    }
  }
}
th {
  .inline {
    .inline-right,
    .inline-left {
      span {
        display: inline-block;
        margin-top: 0;
        margin-left: 3px;
      }
    }
  }
}
.no-border-bottom {
  border-bottom: none !important;
}

.border-bottom-cust {
  border-bottom: 2px solid #fff !important;
}

textarea.custom-height {
  height: 120px;
}

textarea.custom-height-20 {
  height: 70px;

  @media (min-width: 1732px) {
    height: 50px;
  }
}

.custom-margin-unit {
  display: inline-block;
  margin-top: 7px;
  margin-left: -15px;
}
