.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right;
}

/**
 * Common used variables
 * @param {string} $directions
 * @param {number} $values (in em unit)
 */
$directions: top right left bottom;
$values: 0 1 2 3 4 5;
$unit: em;

/**
 * Margin Utilities
 * I.e: m-top-0
 */ 
@each $direction in $directions {
  @each $value in $values {
    .m-#{$direction}-#{$value} {
      @include margin(#{$direction}, #{$value}, #{$unit});
    }
  }
}

/**
 * Padding Utilities
 * I.e: p-top-0
 */ 
 @each $direction in $directions {
  @each $value in $values {
    .p-#{$direction}-#{$value} {
      @include padding(#{$direction}, #{$value}, #{$unit});
    }
  }
}

/**
 * Positioning
 */
.inline-control {
  display: flex;
  align-items: flex-end;
}

.flex-space-between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.flex-space-between-align-centered {
  @extend .flex-space-between;
  align-items: center;
}

/**
 * Sizing
 */
.full-width-input {
  width: 100%;
}
