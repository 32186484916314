@import "libs/styles/variables";

.btn {
  font-weight: 700;
  font-size: 1em;
  font-family: "Segoe UI", Arial, sans-serif;
  text-transform: capitalize;
  //border: 2px solid $primary-black;
  border-radius: 4px;
  padding: 8px 30px;
  transition: all 0.2s ease;

  &:hover,
  &:focus,
  &.focus {
    color: $secondary-white;
    border-color: $color-3;
    background-color: $color-3;
  }

  &:focus,
  &:active,
  &:active:focus,
  &:active:hover,
  &:focus-within {
    color: $secondary-white;
    border-color: $color-3;
    background-color: $color-3;
    box-shadow: none;
    outline: none;
  }

  &:disabled {
    opacity: 1;
    color: $secondary-white;
    border-color: $color-5;
    background-color: $color-5;
  }
}

.btn-link {
  text-decoration: underline;
  border:0;

  &:hover {
    background: none;
    text-decoration: none;
}
}

.btn-primary {
  background-color: $color-1;
  color: $secondary-white;
}

.btn-light {
  background-color: $secondary-white;
}

.cancel-btn {
  background: inherit;
  border: 0;
  text-decoration: underline;
  color: black;

  &:hover,
  &:focus,
  &:active,
  &:active:focus,
  &:active:hover,
  &:focus-within {
    color: inherit;
    border-color: inherit;
    background-color: inherit;
    text-decoration: underline;
  }
}

.btn-add {
  float: right;
  border: none;
  outline: none;
  bottom: 2px;
  left: 100%;
  font-size: 12px;
  line-height: 30px;
  white-space: nowrap;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  padding-left: 20px;
  padding-right: 20px;
  background: $secondary-white;

  i {
    font-size: 16px;
    position: relative;
    bottom: -4px;
    margin-right: 5px;
  }

  span {
    border-bottom: 1px solid $secondary-grey-blue;
  }
}

.btn-attached {
  border: none;
  outline: none;
  bottom: 2px;
  left: 100%;
  font-size: 12px;
  line-height: 30px;
  white-space: nowrap;
  padding-left: 20px;
  padding-right: 20px;
  background: $secondary-white;
  border: 1px solid #cccccc;
  line-height: 30px;
  color: #000;

  &:hover {
    background-color: #f2f2f2;
  }

  &.btn-attached-top {
    border-bottom-width: 0;
    box-shadow: -2px 2px 7px #e8e2e2;
    border-radius: 15px 15px 0 0;
    margin-left: 5px;
  }

  &.btn-attached-bottom {
    border-top-width: 0;
    box-shadow: 2px 2px 7px #e8e2e2;
    border-radius: 0 0 15px 15px;
  }

  i {
    position: relative;
    vertical-align: middle;
    margin-right: 5px;

    &.pi {
      font-size: 16px;
    }

    &.fa {
      font-size: 16px;
    }
  }

  span {
    text-decoration: underline;
    vertical-align: middle;
  }

  &:disabled {
    background-color: #ddd;
    color: #888;

    span {
      text-decoration: none;
    }
  }
}
