// .ui-tab-view-md {
//   .ui-tabview.p-component-content {
//     margin: auto;
//   }

//   .ui-tabview .ui-tabview-nav>li:not(.ui-state-active):not(.p-disabled):hover {
//     background-color: #777;;
//     border-radius: 12px 12px 0px 0px;

//     >a {
//       color: $secondary-white;
//       text-decoration: underline;
//     }
//   }

//   .ui-tabview .ui-tabview-nav>li.ui-state-default {
//     background: rgba(255,255,255,1);

//     >a {
//       color: $secondary-white;
//       text-decoration: underline;
//     }
//   }

//   .ui-tabview .ui-tabview-nav>li:not(.ui-state-active):not(.p-disabled):hover {
//     background: none;
//   }

//   .ui-tabview .ui-tabview-nav li:not(.ui-state-active):not(.p-disabled):hover {
//     background-color: $color-2;
//     border-radius: 12px 12px 0px 0px;

//     >a {
//       color: $secondary-white;
//       text-decoration: underline;
//     }
//   }

//   .ui-tabview .ui-tabview-nav li a {
//     width: 100%;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//   }

//   .ui-tabview .ui-tabview-panel {
//     border-width: 0;
//     padding: 2em;
//     background: 0;
//   }
// //}
.panel-body {
  position: relative;
}

.panel {
  width: 100%;

 
  
  &.panel-custom {
    border: none;

    .panel-heading {
      background-color: #43494e;
      color: #fff;
      border: none;
      border-radius: 0;
      padding: 6px 20px 6px 15px;
      display: inline-block;
      font-weight: bold;

      * {
        font-weight: normal;
        margin: 0;
      }
    }

    .panel-body {
      background-color: #eceff0;
    }
  }

  &.no-padding .panel-body {
    padding: 0;
  }

  &.fracture-gradient .panel-heading {
    margin-left: 1px;
  }

  &.bs-pure-panel {
    // clear border-radus & background from bs styling
    background-color: initial;
    border-radius: unset;
    box-shadow: none;
    margin-bottom: 0;
  }
}

.grey-heading-box {
  background-color: $color-2;
  color: #fff;
  border: none;
  border-radius: 0;
  padding: 6px 15px;
  display: inline-block;
}
