.p-component-overlay {
  background: rgba(41, 40, 41, 0.8);
  z-index: 1000;
  opacity: 1;
  transition: all ease 0.3s;
}

.loading-indicator,
.loading-indicator:after {
  border-radius: 50%;
  width: 40px;
  height: 40px;

}

.loading-indicator {
  font-size: 10px;
  margin: 0 auto;
  margin-top: -20px;
  top: 50%;
  left: 0;
  right: 0;
  position: absolute;
  text-indent: -9999em;
  border-top: 6px solid $secondary-dark-grey;
  border-right: 6px solid $secondary-dark-grey;
  border-bottom: 6px solid $secondary-dark-grey;
  border-left: 6px solid $primary-red;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
