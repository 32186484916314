/**
 * PrimeNG 4.3.0
 * Customization of PrimeNG Message components
 */

// .ui-growl {
//   top: 65px;

//   &.p-component {
//     width: auto;
//     max-width: 400px;
//   }
// }
/*
.ui-growl-item-container.p-highlight.ui-growl-message-info {
  background-color: #0a8fa1;
  border-color: #0a8fa1;
}
*/
// .ui-growl {
//   top: 70px;

//   .ui-growl-item-container {
//     margin: 0 0 1em 0;
//     opacity: 0.9;
//     //filter: alpha(opacity=90);
//     -webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
//     -moz-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
//     box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);

//     .ui-growl-item {
//       padding: 1em;
//     }
//     .ui-growl-item .ui-growl-message {
//       margin: 0 0 0 4em;
//     }
//     .ui-growl-item .ui-growl-image {
//       font-size: 2.571em;
//     }
//     &.ui-growl-message-info {
//       background-color: $secondary-blue;
//       border: 0 none;
//       color: #fff;
//     }
//     &.ui-growl-message-info .ui-growl-image {
//       color: #fff;
//     }
//     &.ui-growl-message-success {
//       background-color: $secondary-green;
//       border: 0 none;
//       color: #fff;
//     }
//     &.ui-growl-message-success .ui-growl-image {
//       color: #fff;
//     }
//     &.ui-growl-message-warn {
//       background-color: $secondary-yellow;
//       border: 0 none;
//       color: #212121;
//     }
//     &.ui-growl-message-warn .ui-growl-image {
//       color: #212121;
//     }
//     &.ui-growl-message-error {
//       background-color: $color-red;
//       border: 0 none;
//       color: #fff;
//     }
//     &.ui-growl-message-error .ui-growl-image {
//       color: #fff;
//     }
//   }
// }

.p-toast {
  top: 70px !important;

  &.p-component {
    width: auto;
    max-width: 400px;
  }

  .p-toast-message {
    font-size: 14px;

    &.p-toast-message-info {
      background-color: $secondary-blue;
      border: 0 none;
      color: #fff;
    }
    &.p-toast-message-info .p-toast-close-icon {
      color: #fff;
    }
    &.p-toast-message-success {
      background-color: $secondary-green;
      border: 0 none;
      color: #fff;
    }
    &.p-toast-message-success .p-toast-close-icon {
      color: #fff;
    }
    &.p-toast-message-success .p-toast-icon-close,
    &.p-toast-message-error .p-toast-icon-close,
    &.p-toast-message-info .p-toast-icon-close {
      color: #fff;
    }
    &.p-toast-message-warn {
      background-color: $secondary-yellow;
      border: 0 none;
      color: #212121;
    }
    &.p-toast-message-warn .p-toast-close-icon {
      color: #212121;
    }
    &.p-toast-message-error {
      background-color: $color-red;
      border: 0 none;
      color: #fff;
    }
    &.p-toast-message-error .p-toast-close-icon {
      color: #fff;
    }
  }
}
