@import "~libs/styles/variables";

.pump-stage-number {
  flex-basis: 100px !important;
  padding-top: 15px;
  cursor: pointer;
}

.pump-stage-tab {
  background: $primary-light-grey-3;
  width: 100%;
  .ui-tabview .ui-tabview-nav>li.ui-state-active>a,
  .ui-tabview .ui-widget-content .ui-tabview-nav>li.ui-state-active>a {
    color: #ffffff !important;
  }
}

tr.pump-fluid-detail-header {
  th {
    background: $secondary-grey-blue-2 !important;
  }
}

tr.pump-event-header {
  th {
    background: $secondary-grey-blue-2 !important;
  }
}
