%invalidCSS {
  color: $secondary-red;
  border: 1px solid $secondary-red;
  box-shadow: 0 3px 6px rgba(168, 168, 168, 0.2);
}

%invalidRawInputCSS {
  @extend %invalidCSS;
}


%invalidInputCSS {
  div.p-dropdown {
    @extend %invalidCSS;
  }

  input {
    @extend %invalidCSS;
  }

  textarea {
    @extend %invalidCSS;
  }

  .ui-dropdown {
    @extend %invalidCSS;
  }

  .ui-autocomplete-input {
    @extend %invalidCSS;
  }

  > .ui-autocomplete > .ui-inputtext {
    @extend %invalidCSS;

    input {
      box-shadow: none;
    }
  }

  > .ui-chkbox {
    @extend %invalidCSS;
  }

  .p-checkbox-box {
    @extend %invalidCSS;
  }
}

%invalidIndicator {
  &.ng-invalid.ng-touched {
    @extend %invalidInputCSS;
  }

  &.ng-invalid.ng-dirty {
    @extend %invalidInputCSS;
  }

  &.ng-dirty.ng-invalid {
    @extend %invalidInputCSS;
  }

  &.ng-touched.ng-invalid {
    @extend %invalidInputCSS;
  }
}

%invalidRawIndicator {
  &.ng-invalid.ng-touched {
    @extend %invalidRawInputCSS;
  }

  &.ng-invalid.ng-dirty {
    @extend %invalidRawInputCSS;
  }

  &.ng-dirty.ng-invalid {
    @extend %invalidRawInputCSS;
  }

  &.ng-touched.ng-invalid {
    @extend %invalidRawInputCSS;
  }
}

input,
textarea {
  @extend %invalidRawIndicator;
}

p-dropdown,
p-autocomplete,
p-inputMask,
p-checkbox,
input-unit,
typeahead-datatable,
autocomplete-ex,
autocomplete-unit,
well-autocomplete-ex {
  @extend %invalidIndicator;
}

typeahead-datatable.ng-invalid.ng-touched {
  input {
    @extend %invalidRawIndicator;
  }

  textarea {
    @extend %invalidRawIndicator;
  }
}

error-display {
  .text-error-message {
    font-size: 0.85em;
    color: $secondary-red;
    margin-top: 3px;
    margin-bottom: 3px;
    display: none;
  }
}

error-display.ng-invalid.ng-touched {
  .text-error-message {
    display: block;
  }
}

error-display.ng-touched.ng-invalid {
  .text-error-message {
    display: block;
  }
}

error-display.ng-dirty.ng-invalid {
  .text-error-message {
    display: block;
  }
}
