/**
 * Base stylesheet
 */

html {
  margin: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  color: $primary-dark-grey;
  font-family: $primary-font;
  font-size: $primary-font-size;
  font-weight: $normal;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100vw;
  overflow-x: hidden;
}

iframe {
  border: none;
  width: 100%;
  height: 100%;
}

a {
  color: $primary-dark-grey;

  &:hover {
    cursor: pointer;
  }
}

.p-component-content {
  color: $primary-dark-grey;
  background: #fff;

  a {
    @extend a;
  }
}

.cpoint-overlay-panel.p-component {
  display: flex;
  border: none;
  color: #fff;
}

.cpoint-overlay-panel.ui-corner-all {
  border-radius: 0px;
}

.external-link {
  color: $secondary-blue !important;
}

.fa-external-link {
  margin-left: 3px;
}

.main-content {
  padding: 30px 50px;
}

#app {
  display: block;
}

.nav-collapsed {
  .adaptive-modal.ui-dialog {
    left: 0 !important;
  }
}

.p-component,
.p-component input,
.p-component select,
.p-component textarea,
.p-component button {
  font-family: $primary-font;
}

.context-menu-toggle {
  &:hover {
    cursor: pointer;
  }
}

.gray {
  width: 100% !important;
  height: 100% !important;
  max-height: 100% !important;
  margin: 0px !important;
  overflow-y: scroll;
}

.dropdown-menu {
  >li>a {
    padding: 7px 30px 7px 10px;
    text-decoration: none;
  }

  i {
    font-size: 18px;
    position: relative;
    bottom: -2px;
    margin-left: 3px;
    width: 24px;
  }

  span {
    margin-left: 3px;
  }
}

.dropdown-menu {
  max-height: 340px;
  left: unset;

  li {
    position: relative;

    &.has-submenu {
      &:hover>ul {
        display: block;
      }

      >ul {
        top: auto;
        bottom: -5px;
        right: 100%;
        max-width: 280px;
        overflow-y: auto;

        a {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }

      >a:after {
        display: block;
        content: " ";
        float: right;
        width: 0;
        height: 0;
        border-color: transparent;
        border-style: solid;
        border-width: 5px 0 5px 5px;
        border-left-color: #ccc;
        margin-top: 5px;
        margin-right: -10px;
      }
    }
  }

  &.show-left {
    left: auto;
    right: 0;

    li {
      &.has-submenu {
        >ul {
          left: auto;
          right: 100%;
        }
      }
    }
  }
}

span.highlight {
  font-weight: bold;
  text-decoration: underline;
  color: black;
  background-color: #fff589;
  // color: white;
  // background-color: #f1322e;
}

.ui-sidebar-full {
  z-index: 100;
}

[hidden] {
  visibility: hidden;
}

.clickable {
  cursor: pointer;
}

.relative {
  position: relative;
}

.fa-envelope-o,
.fa-exclamation-circle {
  color: $primary-red;
  font-size: 1.2em;
  margin: 0px 0px 0px -5px;
}

.fa-img-exclamation {
  width: 22px;
  height: 22px;
  margin: -5px 10px 0px -8px;
}

.fa-info-circle {
  font-size: 1.2em;

  &:hover {
    cursor: pointer;
  }
}

.fa-question-circle {
  //font-size: 1.4em;
  position: relative;
  z-index: 1;

  &:hover {
    cursor: pointer;
  }
}

.more-info {
  position: relative;

  .more-info-icon {
    top: 50%;
    right: -21px;
    position: absolute;
  }
}

.overlay-panel-left {
  position: relative;

  .p-overlaypanel-content {
    max-width: 500px;
    min-width: 250px;
    padding: 15px;
    max-height: 300px;
    overflow-y: auto;
    left: 0 !important;
    transform: translateX(-100%);
    top: 10px !important;

    ul {
      margin: 10px 0 0;
      padding: 0;

      li {
        list-style: none;
        padding: 2px;
      }
    }
  }
}

.overlay-panel-right {
  @extend .overlay-panel-left;

  .p-overlaypanel-content {
    transform: translateX(0);
    background: white;
    color: black;
    border-radius: 5px;
  }

  .p-overlaypanel-close {
    right: auto;
    left: -.5em;
    bottom: 23em;
    width: 2em;
    height: 2em;
    border-radius: 100%;
    //color: $primary-grey;
    background: #fff;
  }

}

.preserve-white-space {
  white-space: pre-wrap;
}

.btn-link {
  .fa-ellipsis-h {
    color: $primary-black;
  }
}

.ui-corner-all {
  border-radius: 4px;
}

.icon-header {
  display: flex;

  >span {
    width: 14px;
    margin-right: 5px;

    &~span {
      margin-right: 0;
    }
  }
}

.fluids-summary-contextmenu.ui-contextmenu {
  padding: 0;
  border: 1px solid #cbcbcb;
  background: #fff;
  border-radius: 0;
  width: 15em;

  .ui-menuitem {

    &.ui-menuitem-active>.ui-menuitem-link,
    >.ui-menuitem-link:hover {
      background-color: #f5f5f5;
      color: $primary-black;
      text-decoration: none;
    }

    >.ui-menuitem-link {
      padding: 6px 12px;
      text-decoration: none;

      &.ui-corner-all {
        border-radius: 0;
      }

      &:hover,
      &:focus,
      &:active {
        background-color: #f5f5f5;
        color: $primary-black;
        text-decoration: none;
      }
    }
  }
}

.events-summary-contextmenu.ui-contextmenu {
  width: 200px;
}

span.sub-header {
  font-size: small;
}

.margin-bottom-50 {
  margin-bottom: 50px;
}

.primary-status-col {
  vertical-align: middle !important;
}

.primary-status {
  display: inline-block;
  font-style: normal;
  height: 25px;
  width: 25px;
  text-align: center;
  line-height: 22px;
  color: #fff;
  border-radius: 100%;
  border: solid 1px #333333;
  color: #333333;
  font-weight: bolder;
  font-size: 12px;
}


.relative {
  position: relative;
}

// link
.lnk {
  .img-icon {
    position: relative;
    top: 4px;
  }
}

// custom icons
.img-icon {
  display: inline-block;
  line-height: 1;

  &.img-icon-delete:before {
    content: ' ';
    display: inline-block;
    width: 18px;
    height: 18px;
    background-image: url($delete-icon-image);
    background-repeat: no-repeat;
    background-size: contain;
  }
}

.cust-jobName {
  white-space: pre-wrap;
}

// customer alert
.alert {
  &.alert-grey {
    background-color: #d4dadd;
    border-color: #d4dadd;
    border-radius: unset;
  }
}

// remove 'X' icon in IE
input::-ms-clear {
  display: none;
}

.p-accordion-tab.p-accordion-tab-active {
  .p-toggleable-content {
    overflow: visible;
  }
}