@mixin margin($direction, $value, $unit) {
  margin-#{$direction}: #{$value}#{$unit};
}

@mixin padding($direction, $value, $unit) {
  padding-#{$direction}: #{$value}#{$unit};
}

@mixin component_form_disable_inner_controls() {
  // wrapped by any component (ex: 'job-edit-container component')
  cursor: not-allowed;

  form, .component-inner-wrapper {
    pointer-events: none;

    textarea, input {
      background-color: rgba(221, 221, 221, 0.65);
    }

    p-dropdown {
      @include pdropdown_disable();
    }

    p-radiobutton {
      .ui-radiobutton .ui-state-default {
        background-color: rgba(221, 221, 221, 0.65);
      }
    }

    .cancel-parent-lock {
      // this will wrap childrent tags
      pointer-events: initial !important;
      cursor: initial !important;
      background-color: initial;

      input, textarea {
        background-color: initial !important;
      }
    }

    // disable exception from submission
    .exception-disable-item {
      pointer-events: none;
      input {
        pointer-events: none;
      }
    }

  }
}

@mixin pdropdown_disable {
  // wrapped by 'p-dropdown'
  cursor: not-allowed;

  > .ui-dropdown {
    position: relative;
    display: inline-block;
    pointer-events: none;

    &:after {
      content: '';
      background-color: rgba(221, 221, 221, 0.65);
      width: 100%;
      height: 28px;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}
