.nav-tabs {
  // border-bottom: 2px solid #ddd;

  > li {
    margin-bottom: -2px;
    > a {
      color: #666;
      border: none;
      // border-bottom: 2px solid #ddd;
      font-weight: 400;
      margin-right: 0;
    }
  }

  > li > a:hover,
  > li > a:focus {
    color: #333;
    background-color: #fff;
    border-bottom: 2px solid #000;
  }

  > li.active > a, 
  > li.active > a:hover,
  > li.active > a:focus {
    font-weight: 500;
    border: none;
    border-bottom: 2px solid #000;
  }
}

.center-tabs {
  .nav-tabs {
    margin: 0px auto;
    width: 1080px;

    > li > a {
      padding-left: 65px;
      padding-right: 65px;
      padding-top: 16px;
      padding-bottom: 5px;
      font-size: 14px;
    }

    > li.active > a,
    > li > a:hover,
    > li > a:focus {
      border-bottom: 4px solid #777;
    }
  }
}