table {
  a {
    text-decoration: underline;
  }
}

.table {
  > thead > tr > th:first-child,
  > tbody > tr > td:first-child {
    padding-left: 0;
  }

  > tbody > tr > td {
    border-top: none;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  > thead > tr > th {
    border-bottom: none;
    font-size: 12px;
  }
}

.table-right-del-container{
  background-color: inherit;
  position: relative;
  .table-right-del-button{
    position: absolute;
    top: 0;
    right: -43px;
    height: -webkit-fill-available;
    width: 40px;
    background-color: inherit;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 23px;
    cursor: pointer;
  }
}

.table-overflow-menu {
  border: none;

  .p-overlaypanel-content {
    padding: 0;
  }

  .dropdown-menu {
    display: block;
  }
}

table.pump-schedule {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;

  th {
    color: #eee;
    padding: 0px 20px;

    span.unit-of-measure {
      font-size: 10px;
      color: #292829;
      margin-left: 3px;
    }
  }

  td {
    padding: 10px 20px;
    > .expanded-stage {
      color: #eee;
    }
  }
  
  .dropdown-toggle {
    i {
      font-size: 8px;
    }
    i + i {
      margin-left: 4px;
    }
  }
}

table.pump-event {
  width: 100%;
  table-layout: fixed;
  border-collapse: separate;
  border: 1px solid #DDDDDD;

  th {
    color: #666;
    background-color: #e4e4e4;
  }

  tr {
    border-radius: 0 5px 5px 0;
  }

  td {
    color: #666;
  }
}

table.fluid {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;

  th {
    background-color: #f3f4f6;
    padding: 15px 20px;

    span.unit-of-measure {
      font-size: 10px;
      color: #292829;
      margin-left: 3px;
    }
  }

  td {
    padding: 10px 20px;
  }
  
  .dropdown-toggle {
    i {
      font-size: 8px;
    }
    i + i {
      margin-left: 4px;
    }
  }
}

table.fluid-detail {
  width: 100%;
  table-layout: fixed;
  border-collapse: separate;
  border-spacing: 0 2px;

  th {
    color: #666;
    background-color: #e4e4e4;
  }

  tr {
    border-radius: 0 5px 5px 0;
  }

  td {
    color: #666;
  }
}

table.table-row-dynamic {
  th.action-delete,
  td.action-delete {
    width: 20px;
    padding: 0;
  }

  td.action-delete {
    position: relative;

    button {
      display: none;
      position: absolute;
      border: none;
      border-radius: 100%;
      width: 70px;
      height: 70px;
      top: 0;
      right: -35px;
      font-size: 25px;
    }
  }

  tr:hover td:not(.action-add) button {
    display: block;
  }

  tr.odd {
    td {
      background-color: #cccccc;
    }

    td.action-delete button {
      background: #cccccc;
    }
  }

  tr.even {
    td {
      background-color: #ffffff;
    }

    td.action-delete button {
      background: #ffffff;
      border-right: 1px ridge;
    }
  }
}

.view-less {
  color: $secondary-white !important;
  font-size: 10px !important;
  margin-left: 10px;
  border-bottom: 1px solid;
}

// .container-control-point {
//   position: relative;
//   >.pump-schedule-cp {
//     width: 75%
//   }
//   >.shoe-track-placement {
//     width: 22%;
//     position: absolute;
//     top: 0;
//     right: 0;
//   }
// }