.top-nav {
  color: $secondary-white;
  padding: 0 34px 0 20px;
  width: 100%;
  height: 64px;
  box-shadow: 0 3px 16px rgba(0, 0, 0, 0.4);
  background: $primary-red;
  position: fixed;
  top: 0;
  z-index: 501;
  width: 100vw;
}

.menu-toggle-button {
  color: $secondary-white;

  .fa {
    font-size: 2em;
  }

  &:hover {
    color: $primary-grey;
  }
}

.main-content {
  transition: all 0.3s;
  padding: 30px 34px;
  margin-left: 190px;
  margin-top: 94px;

  &.content-ifact-warning {
    .sidebar-nav {
      top: 94px !important;
    }
  }

  &.nav-collapsed {
    margin-left: 0;

    .sidebar-nav {
      left: -$sidebar-width;
      box-shadow: none;
    }
  }
}

/* Overflow menu */
.open {
  position: relative;
}
