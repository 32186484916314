/**
 * INPUT
 */
input {
  text-overflow: ellipsis;
  padding: 4px 8px;
}

select {
  padding: 5px 30px 5px 10px !important;
  border: 1px solid $secondary-grey;
  text-overflow: ellipsis;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-repeat: no-repeat;
  background-size: 15px 15px;
  background-position: calc(100% - 8px) center;
  background-image: url($chevron-down-black-image) !important;

  &:hover {
    cursor: pointer;
  }
}

select::-ms-expand {
  display: none;
}

.alert-danger {
  border: 1px solid #ABB2B9;
  background-color: #FAE6E6;
}

.form-control {
  border-radius: 0;
  box-shadow: none;
  border: 1px solid $secondary-grey;
  padding: 4px 8px;
  height: 30px;
  box-shadow: 0 3px 6px rgba(168, 168, 168, 0.2);
  transition: all 0.2s ease;
}

.form-group-with-unit {
  .form-control {
    max-width: 70%;
    margin-right: 5px;
  }
}

textarea {
  resize: none;
}

.disable-inner-elements {
  cursor: not-allowed;

  > * {
    pointer-events: none;
  }

  input,
  select,
  textarea,
  a.disable-color,
  .ui-calendar input.ui-inputtext {
    background-color: #eee;
  }

  .ui-dropdown {
    .ui-dropdown-label,
    .ui-dropdown-trigger {
      background-color: #eee;
    }
  }
}
