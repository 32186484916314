.p-tabview ul.p-tabview-nav {
    display: flex;
    margin-left:1px;

    > li {
        margin-right: 1px;

        a {
            border-radius: 5px 5px 0px 0px;
            padding: 0.3em 1.4em;
            color: $primary-black;
            background: #fff;
            text-align: center;
            display: block;
            transition: 0.1s;
            text-decoration: none;
            font-weight: 600;

            &:hover {
                color: $secondary-white !important;
                background: $color-3;
                text-decoration: none;
            }
        }

        &.p-highlight {
            > a {
                background-color: $secondary-grey-blue-2;
                color: $secondary-white !important;
                //text-decoration: underline;

                &:after {
                    content: "";
                    width: 0px;
                    height: 0px;
                    border-left: 8px solid transparent;
                    border-right: 8px solid transparent;
                    border-top: 8px solid $color-3;
                    position: absolute;
                    left: 50%;
                    margin-left: -5px;
                    bottom: -8px;
                    background-color: $color-3;
                }
            }
        }
    }
}

.p-tab-view-md .p-tabview ul.p-tabview-nav {
    > li {
        a {
            padding: 1em 2em;
            border-radius: 8px 8px 0px 0px;
        }

        &.p-highlight a {
            background: $color-3;
        }
    }
}

.p-tab-view-lg > .p-tabview, .p-tabview-nav-content {
    > ul.p-tabview-nav {
        border-bottom: 1px solid #ccc;

        > li {
            max-width: 350px;
            margin-right: 15px;
            flex: 1 0 auto;

            a {
                border: 1px solid $color-4;
                border-bottom: 0;
                padding: 0;
                border-radius: 12px 12px 0px 0px;
                height: 100%;
                //background: #f7f7f7;
            }
        }
    }

    > .p-tabview-panels {
        padding: 2em 0;
    }
}

.ui-tabview {
    .ui-tabview-panels {
        border: 0 !important;
    }
}

.p-tab-view-md {
    .ui-tabview .ui-tabview-panel {
        padding: 0px;
    }

    .ui-tabview.p-component-content {
        background: $primary-light-grey-3;
        margin: auto;
    }

    .ui-tabview.ui-tabview-top > .ui-tabview-nav {
        display: flex;
        margin-left: 15px;
        border-bottom: 0px solid black !important;
    }

    .ui-tabview.ui-tabview-top > .ui-tabview-nav li {
        border: 0px solid;

        a {
            padding: 7px 28px 5px 23px;
        }
    }

    .ui-tabview .ui-tabview-nav > li.ui-state-active {
        background-color: $secondary-grey-blue-2 !important;
        border-radius: 12px 12px 0px 0px;
        z-index: 2 !important;

        > a {
            color: $secondary-white !important;

            &:after {
                content: "";
                display: block !important;
                clear: both !important;
                border: 0px solid $primary-black !important;
            }
        }
    }

    .ui-tabview .ui-tabview-nav > li.ui-state-default {
        background-color: $secondary-white;
        border-radius: 12px 12px 0px 0px;
        margin-right: -5px;
        z-index: 1;

        > a {
            color: $primary-black !important;
            font-size: 12px !important;
        }
    }

    .ui-tabview .ui-tabview-nav > li:not(.ui-state-active):not(.p-disabled):hover {
        background: none;
    }

    .ui-tabview .ui-tabview-nav li:not(.ui-state-active):not(.p-disabled):hover {
        background-color: $secondary-grey-blue-2;
        border-radius: 12px 12px 0px 0px;

        > a {
            color: $secondary-white !important;
        }
    }

    .ui-tabview .ui-tabview-nav li a {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        > span {
            display: inline-block;
            padding-bottom: 2px;
            font-weight: bold;
        }
    }
}

.modal-content .p-tabview .header-top-error {
    border-bottom: 4px solid $primary-red !important;

    > a {
        color: $primary-red;
    }
}

.ui-tab-view-md .ui-tabview .ui-tabview-nav > li.ui-state-active > a,
.ui-tab-view-md .ui-tabview .p-component-content .ui-tabview-nav > li.ui-state-active > a {
    color: $secondary-white !important;
}
