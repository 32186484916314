$px-steps: 30;
@mixin margin-padding-generator {
    .no-m-l {
        margin-left: 0px !important;
    }
    .no-m-t {
        margin-top: 0px !important;
    }
    .no-m-r {
        margin-right: 0px !important;
    }
    .no-m-b {
        margin-bottom: 0px !important;
    }
    @for $i from 0 through $px-steps {
        .m-l-#{$i * 5} {
            margin-left: $i * 5px !important;
        }
        .m-t-#{$i * 5} {
            margin-top: $i * 5px !important;
        }
        .m-r-#{$i * 5} {
            margin-right: $i * 5px !important;
        }
        .m-b-#{$i * 5} {
            margin-bottom: $i * 5px !important;
        }
        .m-#{$i * 5} {
            padding: $i * 5px !important;
        }
        .p-l-#{$i * 5} {
            padding-left: $i * 5px !important;
        }
        .p-t-#{$i * 5} {
            padding-top: $i * 5px !important;
        }
        .p-r-#{$i * 5} {
            padding-right: $i * 5px !important;
        }
        .p-b-#{$i * 5} {
            padding-bottom: $i * 5px !important;
        }
        .p-#{$i * 5} {
            padding: $i * 5px !important;
        }
    }
}